import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
//
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
//
import { ErrorHandlingService } from '../../../../error-handling/services/error-handling.service';
import { CompanyDepartmentService } from './company-department.service';
import { AuthService } from 'app/authentication/services/auth.service';

const errorKey = 'Error';

@Injectable({
    providedIn: 'root'
})
export class CompanyDepartmentResolveService implements Resolve<any> {

    constructor(
        private authService: AuthService,
        private companyDepartmentService: CompanyDepartmentService,
        private errorHandlingService: ErrorHandlingService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.companyDepartmentService.getAllCompanyDepartment().pipe(
            map(brands => brands),
            catchError((err) => {
                this.errorHandlingService.handleUiError(errorKey, err);
                return of(null);
            }));
    }
}
