import { Injectable } from '@angular/core';
import { ErrorHandlingHttpService } from 'app/error-handling/services/error-handling-http.service';
import { ConfigService } from 'app/config/services/config.service';
import { Observable } from 'rxjs';
import { Invitation } from '../models/invitation';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  apiEndpoint: string;

  constructor(private configService: ConfigService,
    private http: ErrorHandlingHttpService) {
    this.apiEndpoint = this.configService.apiUrl + this.configService.config.apiConfigs.invitations.apiEndpoint;
  }


  inviteUsers(invitations: Invitation, language?: string): Observable<Invitation> {
    let queryParams = '';
    if (language) {
      queryParams = `?lang=${language}`;
    }
    return this.http.post<Invitation>(this.apiEndpoint + queryParams, JSON.stringify(invitations));
  }

  getInvitationByToken(token: string): Observable<Invitation> {
    return this.http.get<Invitation>(this.apiEndpoint + '/' + token);
  }

}
