import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
//
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
//
import { ErrorHandlingService } from '../../../../error-handling/services/error-handling.service';
import { LibraryService } from './library.service';
import { Theme } from '../models/library';

const errorKey = 'Error';

@Injectable({
  providedIn: 'root'
})
export class SubjectsResolveService implements Resolve<Theme[]> {
  constructor(
    private libraryService: LibraryService,
    private errorHandlingService: ErrorHandlingService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Theme[]> {
    return this.libraryService.getSubjects().pipe(
      catchError((err) => {
        this.errorHandlingService.handleUiError(errorKey, err);
        return of(null);
      }));
  }
}
