import {Unit} from './unit';

export class User {
  id?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  role: string;
  groupId?: string;
  groupName?: string;
  branchId?: string;
  branchName?: string;
  unitId?: string;
  unitName?: string;
  cosmeId?: string;
  cosmeName?: string;
  unit?: Unit;
  email: string;
  image?: string;
  updatedAt?: Date;
  createdAt?: Date;
  departmentId?: string;
  permission?: string;
  companyPermission?: string;
  companyId?: string;
  companyName?: string;
  cosmeType?: string;

  canEditCompanyPermission?: boolean;
  defaultCompanyPermission?: string;

  description?: string;

  createdCompanies?: number;
  maxCompanies?: number;
  maxCompanyAdmins?: number;
  maxCompanyUsers?: number;
  scopeId?: string;

  showNewVersionImprovements?: boolean;
}
